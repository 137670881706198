import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import type { ICaseRevisionItem, IDropItemDto } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { LangRouterModule, SkinItemImageModule } from '@app/shared/directives';
import { ImageErrorHandlerDirective } from '@app/shared/directives/image-error-handler/image-error-handler.directive';
import { colorDictionary } from '@app/shared/utils';

import { UserAvatarComponent } from '../user-avatar';

@Component({
  selector: 'app-ui-drop-card',
  templateUrl: './drop-card.component.html',
  styleUrls: ['./drop-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    SkinItemImageModule,
    UserAvatarComponent,
    TranslateModule,
    RouterLink,
    LangRouterModule,
    MatIcon,
    CurrencyComponent,
    ImageErrorHandlerDirective,
  ],
})
export class DropCardComponent {
  replayLink = computed(() => {
    const drop = this.drop();
    return drop?.isBattle ? `/game/case-battle/${drop.id}` : `/game/cases/case/${drop?.case.id}`;
  });

  drop = input<IDropItemDto | undefined>();
  list = input<'last' | 'best'>();
  className = input<string>();
  isFullView = input<boolean>(false);
  clickOnCard = output<ICaseRevisionItem>();

  isBoxFocused = signal<boolean>(false);
  isPlayerFocused = signal<boolean>(false);

  navigateToCaseItem(itemRevisions: ICaseRevisionItem): void {
    this.clickOnCard.emit(itemRevisions);
  }

  style = computed<{ background: string } | void>(() => {
    const drop = this.drop();
    if (!drop) {
      return;
    }
    const colorDTO = drop.caseRevisionItem.inventoryItem.baseItem.color;
    const newColor = colorDictionary[colorDTO.toUpperCase()];
    return {
      background: `linear-gradient(90.57deg, #121A1E -15%, ${newColor} 30%, ${newColor} 50%, #121A1E 150%)`,
    };
  });

  backgroundImage = computed<{ 'background-image': string } | void>(() => {
    const drop = this.drop();
    if (!drop) {
      return;
    }
    if (drop.case.backgroundImage) {
      return {
        'background-image': `url(${drop.case.historyImage})`,
      };
    }
    return {
      'background-image': `url(${drop.caseRevisionItem.inventoryItem.baseItem.icon})`,
    };
  });

  onElementHoverEnter(type: string): void {
    this.isBoxFocused.set(type === 'box');
    this.isPlayerFocused.set(!this.isBoxFocused());
  }

  onElementHoverLeave(): void {
    this.isBoxFocused.set(false);
    this.isPlayerFocused.set(false);
  }
}
